import baseUrl from '../../Endpoints';

export default async function AppUsuarios(idChannel) {
    const url = `${baseUrl}/dev/verifica-quantidade-usuarios`;
    let pin = await sessionStorage.getItem('pin');
    let uniqueID = await localStorage.getItem('uniqueID');

    if (!uniqueID) {
      var randomString = require('random-string');
      uniqueID = randomString({length: 40});
      await localStorage.setItem('uniqueID', uniqueID);
    }

    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });

    const task = {
      "idsessaocanal": idChannel,
      "idterminal": uniqueID,
      "pin": pin
    }

    const data = JSON.stringify(task);
    const request = new Request(url, {
      method: 'POST',
      headers: headers,
      body: data,
    });

    const getFetch = fetch(request);
    const [getPromise] = await Promise.all([getFetch]);
    const getJson = await getPromise.json();
    return getJson.type;
    
}