import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, ContainerBox, Title, Password, Enter, PasswordBox, Alert } from './styles';
import Header from '../../components/Header';
import LanguageJson from '../../Language/RestrictChannel';
//import Arrow from '../../assets/images/arrow.svg';
import BackBTN from '../../components/BackBTN';

export default function RestrictChannel({ location }) {
  const [sessaoId, setSessaoId] = useState('')
  const [value, setValue] = useState('');
  const [error, setError] = useState(false)

  const selectedChannel = useSelector((state) => state.selectedChannel);
  const history = useHistory();
  const language = useSelector((state) => state.language);
  //const [channelList, setChannelList] = useState([]);

  async function handleBackPage() {
    history.push('./rooms');
  }


  //---------------------------------------------------


  async function handleText(event) {
    let text = event.target.value;
    if (text.length <= 6) {
      setValue(text.toUpperCase());
    }
  }

  async function handlePassword(e) {
    if (e.key === 'Enter' || e === 'btn') {
      setError(false);
      const getPassword = document.querySelector('.passwordText').value;
      if (getPassword === selectedChannel.sessao_senha) {
        return history.push('./channel')
      } else {
        setError(true);
      }
    }
  }


  return (
    <Container>
      <Header />
      <BackBTN handleBackPage={handleBackPage} language={language} />
      <ContainerBox>
        <Title>{LanguageJson[language].RestrictChannel.title}</Title>
        <PasswordBox>
          <Password type="text" placeholder={LanguageJson[language].RestrictChannel.placeholder} autoFocus spellCheck="false" onKeyUp={handlePassword} onChange={(event) => handleText(event)} className="passwordText" value={value} />
          <Enter onClick={() => handlePassword('btn')}>{LanguageJson[language].RestrictChannel.button}</Enter>
          <Alert>{error ? LanguageJson[language].RestrictChannel.error : null}</Alert>
        </PasswordBox>
  
          
  

      </ContainerBox>
    </Container>
  )
}