import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...resto }) => {
  const auth = useSelector((state) => state.auth);

  return <Route {...resto} render={props => (
    auth ? (
      <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
  )} />
}

export default PrivateRoute;