import styled from 'styled-components';

export const HeaderStyle = styled.div`
  display: flex;
  position: absolute;
  background-color: #010f5c;
  top: 0;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 0 30px;

  @media (max-width: 700px) {
  
  }

  @media (max-width: 620px) {
    height: 60px;
  }
`;

export const BackSpace = styled.div`
  height: 60px;
  width: 100%;
`;

export const BotaoTeste = styled.button`
    margin-left: 2%;
    color: #fff;
    border: none;
    outline: none;
    padding: 8px 30px;
    background-color: #4d5fc1;
    cursor: pointer;
    border-radius: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-variant: small-caps;
    font-size: 14px;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
`;