import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, ContainerBox, TextInfos, EventoTitulo, EventoBannerBox, EventoBannerIMG, EventoLocal, EventoDatas, DataInicio, DataFim, NextButton } from './styles';
import Header from '../../components/Header';
import LanguageJson from '../../Language/Home';

export default function Home() {
  const app_busca_evento = useSelector((state) => state.app_busca_evento);
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const [info, setInfo] = useState({ evento_titulo: '', evento_local: '', evento_banner: '', evento_data_inicio: '', evento_data_fim: '' });

  useEffect(() => {

  }, [language])

  useEffect(() => {
    
    (async () => {
      let getAuth = await sessionStorage.getItem('auth');
      if (getAuth === false) {
        return history.push('./');
      }



      let getStorage = await sessionStorage.getItem('data');
      getStorage = JSON.parse(getStorage);
      if (!getStorage || getStorage.app_busca_evento.evento === undefined) {
        dispatch({ type: 'change_auth', current: false });
      } else {
        const { evento } = getStorage.app_busca_evento;
        setInfo({
          evento_titulo: evento.evento_titulo,
          evento_local: evento.evento_local,
          evento_banner: evento.evento_banner,
          evento_data_inicio: evento.evento_data_inicio,
          evento_data_fim: evento.evento_data_fim
        })
      }
    })()
  }, [])

  useEffect(() => {

    (async () => {
      let getStorage = await sessionStorage.getItem('data');
      getStorage = JSON.parse(getStorage);
      const { evento } = getStorage.app_busca_evento;
      setInfo({
        evento_titulo: evento.evento_titulo,
        evento_local: evento.evento_local,
        evento_banner: evento.evento_banner,
        evento_data_inicio: evento.evento_data_inicio,
        evento_data_fim: evento.evento_data_fim
      })
    })();

  }, [app_busca_evento]);

  async function handleNextPage() {
    history.push('/rooms')
  }

  const { evento } = app_busca_evento;
  return (
    <>
      <Container>
        <Header />
        <ContainerBox>
        <EventoBannerBox><EventoBannerIMG src={info.evento_banner} alt="evento banner" /></EventoBannerBox>
          <TextInfos>
            <EventoTitulo><div>{LanguageJson[language].Home.nomeEvento}: </div>{info.evento_titulo}</EventoTitulo>
            <EventoLocal><div>{LanguageJson[language].Home.localEvento}: </div>{info.evento_local}</EventoLocal>
            <EventoDatas>
              <DataInicio><div>{LanguageJson[language].Home.dataInicio}: </div>{info.evento_data_inicio}</DataInicio>
              <DataFim><div>{LanguageJson[language].Home.dataFinal}: </div>{info.evento_data_fim || '-'}</DataFim>
            </EventoDatas>
          </TextInfos>
          
        </ContainerBox>
        <NextButton onClick={handleNextPage}>{LanguageJson[language].Home.nextButton}</NextButton>
      </Container>
    </>
  )
}