import styled from 'styled-components';

import bgImage from '../../assets/images/bg.png';

//

export const Container = styled.div`
  :root {
    --main-color: #718fc8;
  }

  background-image: url(${bgImage});
  width: 100%;
  height: 100vh;  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

`;

export const ContainerBG = styled.div`
  width: 100%;
  height: 100vh;
  background: #001279a1;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export const PontoLogo = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;


  img {
    width: 220px;
  }

  @media (max-height: 500px) {
    height: 80px;

    img {
      width: 150px;
    }
  }

  @media (max-width: 520px) {   
    height: 90px;

    img {
      width: 150px;
    }
  }

  
`;

export const LoginBox = styled.div`
  width: 96vw;
  max-width: 500px;
  height: 150vw;
  max-height: 400px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    margin: 30px 0;
    text-align: center;
    color: #055ff080;
    font-size: 20px;
    font-style: italic;
  }

  @media (max-height: 500px) {
    height: 350px;
    font-size: 12px;
  }

  @media (max-width: 520px) {   
    height: 350px;
    font-size: 12px;
  }


`;

export const LoginForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  
  button {
    border: none;
    width: 80%;
    height: 50px;
    background-color: ${(props) => props.isLoading ? '#fff' : '#055ff0'};
    color: #fff;
    font-weight: bolder;
    letter-spacing: 1px;
    font-size: 16px;
    font-variant: small-caps;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    margin: 20px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  small {
    position: relative;
    top: -10px;
  }

  @media (max-height: 500px) {
    button {
      height: 45px;
    }
  }

  @media (max-width: 520px) {
    button {
      width: 94%;
    }

    small {
      position: relative;
      top: -10px;
      left: -30px;
    }
  }

  

`;


export const LoginPassword = styled.div.attrs({
  placeholder: 'aaaaaaaaa',
})`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:  6px;
    overflow: hidden;
    margin: 20px 0;
    height: 50px;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
    font-size: 14px;
    background-color: #dedede90;
    border: none;
    }


    @media (max-height: 500px) {
      margin: 8px 0;
    }

    @media (max-width: 520px) {
      width: 94%;

      input {
        padding: 10px 0;
      }

    }
  
`;

export const Lock = styled.div`
  height: 100%;
  width: 80px;
  background-color: #dedede90;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 520px) {
    width: 50px;
  }

`;

export const Loading = styled.img`
  width: 300px;
  height: 200px;
`;

export const Alert = styled.small`
  color: #f00;
  align-self: flex-start;
  margin-left: 12%;
`;

export const Modal = styled.div`
  position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.15);
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: ${(props) => props.aberto ? 'visible' : 'hidden'};
  z-index: 999999;
`;

export const ModalContent = styled.div`
	background-color: white;
	width: 30%;
	padding: 2%;
	border-radius: 5px;
  opacity: 0.98;
  padding: 4vh 2vw;

  @media only screen and (max-width: 768px){
    width: 80%;
  }
`;

export const ModalBody = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 8vh;
  justify-content: center;
  flex-wrap: wrap;
  

  h1{
    width: 100%;
    text-align: center;
    padding: 3vh 0 2vh 0;
    font-size: 18px;
    color: #055FF0;
  }
  
  p{
    text-align: center;
    font-weight: 600;
    color: black;
    font-size: 16px;
  }
`;

export const ModalFooter = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;

  a{
    color: #055FF0;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
  }
  a:first-child{
    opacity: 0.65;
  }
`;

