import styled from 'styled-components';

export const Button = styled.button`
  color: #fff;
  border: none;
  outline: none;
  padding: 8px 30px;
  background-color: #4d5fc1;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-variant: small-caps;
  font-size: 16px;
  letter-spacing: 1px;

  small {
    margin-bottom: 3px;
}

  @media (max-width: 620px) {
    padding: 10px 14px;
    font-size: 14px;
  }

`;


export const Img = styled.img`
  
    width: 14px;
    margin-right: 10px;
    align-self: center;

    @media (max-width: 620px) {
      width: 10px;
      margin-right: 4px;

    }
`;