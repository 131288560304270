module.exports = {
  pt: {
    RestrictChannel: {
     title: 'Senha da sala',
     placeholder: 'Senha de 6 caracteres',
     button: 'entrar',
     error: 'Senha incorreta! Por favor, tente novamente.',
    }
  },
  en: {
    RestrictChannel: {
      title: "Room's password",
      placeholder: '6 character password',
      button: 'Sign in',
      error: 'Incorrect password! Please try again.',
    }
  }
}
