module.exports = {
    pt: {
        exit: {
            alert: 'Você foi desconectado do sistema Pontolink com sucesso.',
            button: 'Tela de login.',
        }
    },
    en: {
        exit: {
            alert: 'You have been successfully disconnected from the Pontolink system.',
            button: 'Login screen',
        }
    }
}