import Header from '../../components/Header';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackBTN from '../../components/BackBTN';
import { Container, ContainerBox, Title, Micro, Logout, Channels, Mute, AudioMenu } from './styles';
import { useHistory } from 'react-router-dom';
import Microphone from '../../assets/images/giphy.jpg';
import LogoutIcon from '../../assets/images/logout.svg';
import ChannelsIcon from '../../assets/images/backw.svg';
import MuteIconOn from '../../assets/images/sound-on.svg';
import MuteIconOff from '../../assets/images/sound-off.svg';
import translate from '../../Language/audio';
import AppBuscaSessao from '../../components/AppBuscaSessao';
import AppLog from '../../components/AppLog';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { PeerConnection } from '@millicast/sdk';
import { Director, View, Logger } from '@millicast/sdk';

window.Logger = Logger

Logger.setLevel(Logger.DEBUG);

export default function AudioWifi() {
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const app_busca_evento = useSelector((state) => state.app_busca_evento);
  const app_busca_sessao = useSelector((state) => state.app_busca_sessao);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [selectedChannel, setSelectedChannel] = useState({});
  const [status, setStatus] = useState(0);
  const [mudo, setMudo] = useState(false);
  const audioElement = useRef(null);
  const capabilities = PeerConnection.getCapabilities('audio');

  let statusPt = ['Conectando', 'Erro ao conectar', 'Ao vivo', 'Em espera'];
  let statusEn = ['Connecting', 'Error to connect', 'Live', 'On hold'];
  
  async function sair() {
    await sessionStorage.removeItem('selectedRoom');
    await sessionStorage.removeItem('selectedChannel');
    await sessionStorage.removeItem('data');
    history.push('./exit');
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      let uniqueID = await localStorage.getItem('uniqueID');
      const getSelectedChannel = await sessionStorage.getItem('selectedChannel');
      await AppLog(uniqueID, JSON.parse(getSelectedChannel).canal_id);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let audio = audioElement.current;
    return () => {
      audio.pause();
    }
  }, [])

  useEffect(() => {
    (async function() {
      const getSelectedRoom = await sessionStorage.getItem('selectedRoom');
      const getSelectedChannel = await sessionStorage.getItem('selectedChannel');
      setSelectedRoom(JSON.parse(getSelectedRoom));
      setSelectedChannel(JSON.parse(getSelectedChannel));
      setMudo(false);

      const constraints = window.constraints = {
        audio: true,
        video: false
      };
      

      // Verificar SO. Se não for Linux, Windows ou Android
      // deve-se requisitar permissão.
      let platform = window.navigator.platform;
      platform = platform.toLowerCase();

      if (platform.includes('Linux') || platform.includes('linux')
          || platform.includes('Android') || platform.includes('android')
          || platform.includes('Win') || platform.includes('win')
          || platform.includes('Windows') || platform.includes('windows')) {
        openConnectionMillicast();
      } else {
        openConnectionMillicast();
      }

      let uniqueID = await localStorage.getItem('uniqueID');
      if (!uniqueID) {
        var randomString = require('random-string');
        uniqueID = randomString({length: 40});
        await localStorage.setItem('uniqueID', uniqueID);
      }

      /*const interval = setInterval(() => {
        AppLog(uniqueID, JSON.parse(getSelectedChannel).canal_id);
        console.log("Teste");
      }, 10000);
      return () => clearInterval(interval);*/
      
    })()
  }, []);

  useEffect(() => {
    (async function() {
      let getSelectedChannel = await sessionStorage.getItem('selectedChannel');
      getSelectedChannel = await JSON.parse(getSelectedChannel);
      let getSelectedRoom = await sessionStorage.getItem('selectedRoom');
      getSelectedRoom = await JSON.parse(getSelectedRoom);

      //--- Verifica o estado CANAL ---
      const response = await AppBuscaSessao(getSelectedRoom.sessao_id);
      for(var aux = 0; aux < response.canais.length; aux++) {
        if (response.canais[aux].canal_id === getSelectedChannel.canal_id) {
          if (response.canais[aux].canal_estado !== 1) {
            return history.push('./channel')
          }    
        }
      }

      //--- Verifica o estado da SESSÃO ---
      
      if (response.sessao.sessao_estado === 2) {
        if (status === 2)
          setStatus(3);
      } else if (response.sessao.sessao_estado === 3 && status == 3) {
        setStatus(2);
      } else if (response.sessao.sessao_estado !== 3) {
        history.push('./rooms');
      }
      
    })()
  }, [app_busca_evento, app_busca_sessao])

  async function openConnectionMillicast(stream) {
    let getSelectedChannel = await sessionStorage.getItem('selectedChannel');
    let url_millicast = JSON.parse(getSelectedChannel).canal_URL_broadcast;
    if (url_millicast === "" || url_millicast === undefined) {
      setStatus(1);
      return;
    }
    let streamName = url_millicast.split('/')[4]; //Replace with your stream name.
    let accountId = url_millicast.split('/')[3].split('=')[1]; //Replace with your account id.

    // Create callback to generate a new token
    const tokenGenerator = ()  => Director.getSubscriber({
      streamName: streamName,
      streamAccountId: accountId,
    });

    const millicastView = new View(streamName, tokenGenerator, null, true);

    millicastView.on('track', (event) => {
      console.log(event.streams[0]);
      audioElement.current.srcObject = event.streams[0];
    })

    try {
      await millicastView.connect();
      setStatus(2);
      handleMute();
    } catch (e) {
      console.error(e);
      await millicastView.reconnect();
    }

  }

  //const [estatoAtual, alterarEstado] = useState('estado inicial do state');
  //exemplo → [count, setCount] = useState(0);
  // para adicionar 1 ao estado atual → setCount((state) => state + 1);

  async function handleLogout() {
    if (status === 0)
      return;
    confirmAlert({
      title: language === 'pt' ? 'Sair' : 'Logout',
      message: language === 'pt' ? 'Deseja sair?' : 'Do you want to exit?',
      buttons: [
        {
          label: language === 'pt' ? 'Sim' : 'Yes',
          onClick: () => sair()
        },
        {
          label: language === 'pt' ? 'Não' : 'No',
          onClick: () => {}
        }
      ]
    });
  }

  async function handleChannels() {
    if (status === 0){
      return;
    }
    await sessionStorage.removeItem('selectedChannel');
    await sessionStorage.removeItem('data');
    history.push('./channel');
  }

  function handleMute() {
    setMudo(!mudo);
    audioElement.current.muted = mudo;
  }

  return (
    <Container>
      <Header />
      <BackBTN handleBackPage={handleChannels} language={language} />
      <ContainerBox>
        <Title>{translate[language].title}</Title>   
        <div><h3>{selectedRoom.sala_nome}</h3></div>
        <div><h3>{language === 'pt' ? selectedChannel.canal_nome : 
            selectedChannel.canal_ingles}</h3></div>
        <div><h3>{language === 'pt' ? statusPt[status] : statusEn[status]}</h3></div>
        <Micro><img src={Microphone} alt="Microphone" /></Micro>
        <audio autoPlay ref={audioElement}></audio>
        <AudioMenu>
          <Logout onClick={handleLogout}><img src={LogoutIcon} alt="Logout" /></Logout>
          <Channels onClick={handleChannels}><img src={ChannelsIcon} alt="Channels" /></Channels>
          <Mute onClick={handleMute}><img src={mudo ? MuteIconOn : MuteIconOff} alt="Mute" /></Mute>
        </AudioMenu>
        
      </ContainerBox>
    </Container>
  )
}