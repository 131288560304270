import baseUrl from '../../Endpoints';

export default async function AppLog(uniqueID, idChannel) {
    const url = `${baseUrl}/dev/app-log-canal`;
    let pin = await sessionStorage.getItem('pin');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });

    const task = {
      "terminal_id": uniqueID,
      "channel_id": idChannel,
      "pin": pin
    }

    const data = JSON.stringify(task);
    const request = new Request(url, {
      method: 'POST',
      headers: headers,
      body: data,
    });

    const getFetch = fetch(request);
    const [getPromise] = await Promise.all([getFetch]);
    const getJson = await getPromise.json();
    console.log(getJson);
  return getJson;
    
}