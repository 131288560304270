import { useRef } from 'react';
import { PeerConnection } from '@millicast/sdk';
import { Director, View } from '@millicast/sdk';
import { Container, ContainerBG, Input, Submit } from './styles';


export default function TesteSDK() {

    const audioElement = useRef(null);
    const streamName = useRef(null);
    const streamAccId = useRef(null);
    const capabilities = PeerConnection.getCapabilities('audio');
    console.log("teste SDK");
    console.log(capabilities);

    const conectarAudioStream = async (view) => {

        try {
            await view.connect();
          } catch (e) {
            console.error(e);
            //await view.reconnect();
          }
          
    };

    const handleSubmit = () => {

        console.log(`
        Stream Name: ${streamName.current.value}\n
        Stream Acc Id: ${streamAccId.current.value}
        `)

        // Create callback to generate a new token
        const tokenGenerator = ()  => Director.getSubscriber({
        streamName: streamName.current.value,
        streamAccountId: streamAccId.current.value,
        });
    
        // Create Millicast instance
        const millicastView = new View('teste', tokenGenerator,audioElement.current);
        conectarAudioStream(millicastView);
    }


    return (
        <Container>
            <ContainerBG>
                <div>
                    <Input type='text' placeholder='Stream Name' ref={streamName}></Input>
                    <Input type='text' placeholder='Stream Account Id' ref={streamAccId}></Input>
                    <Submit type='submit' value="Conectar" onClick={handleSubmit}></Submit>
                </div>
                
                <audio autoPlay ref={audioElement}></audio>
            </ContainerBG>
        </Container>
    );
}

