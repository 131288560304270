import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @keyframes anima {
    from {
      transform: translateX(-26px);
      opacity: 0;
    }; 
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

`;

export const ContainerBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;

    @media (max-width: 1300px) {
      width: 70%;
    }

    @media (max-width: 1000px) {
      width: 90%;
    }

    @media (max-width: 700px) {
      width: 96%;
    }

    @media (max-width: 620px) {
      width: 96%;
      flex-direction: column;
      margin-top: 30px;
    }
    
`;

export const TextInfos = styled.div`
  width: 30vw;
  min-width: 200px;
  text-align: center;
  flex:1;

  @media (max-width: 700px) {
    width: 96vw;
  }

  @media (max-width: 620px) {
    width: 96vw;
    margin-bottom: 20px;
    transform: translateY(-10px);
  }
`;

export const EventoTitulo = styled.div`
  animation-name: anima;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00000030;
  padding: 20px 10px;
  margin-right: 20px;
  
  div {
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    width: 200px;
  }

  @media (max-width: 620px) {
    div {
      font-size: 16px;
    }
  }
`;

export const EventoBannerBox = styled.div`
    animation-name: anima;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    height: 100%;
    background-color: #00000010;
    border-radius: 10px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vw;
    max-width: 700px;

    @media (max-width: 620px) {
      width: 90vw;
      height: 100%;
      padding: 0;
      background-color: transparent;
    }
    
`;

export const EventoBannerIMG = styled.img`
  width: 100%;
  max-width: 450px;
  border-radius: 6px;
  overflow: hidden;
  animation-name: anima;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
`;

export const EventoLocal = styled.div`
  animation-name: anima;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00000030;
  padding: 20px 10px;
  margin-right: 20px;

  div {
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    width: 200px;
  }


  @media (max-width: 620px) {
    div {
      font-size: 16px;
    }
  }
`;

export const EventoDatas = styled.div`

  `;

export const DataInicio = styled.div`
  animation-name: anima;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00000030;
  padding: 20px 10px;
  margin-right: 20px;

  div {
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    width: 200px;
  }

  @media (max-width: 620px) {
    div {
      font-size: 16px;
    }
  }
`;

export const DataFim = styled.div`
  animation-name: anima;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00000030;
  padding: 20px 10px;
  margin-right: 20px;

  div {
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    width: 200px;
  }

  @media (max-width: 620px) {
    div {
      font-size: 16px;
    }
  }

`;

export const NextButton = styled.button`
  @keyframes animaButton {
    from {
      opacity: 0;
    }; 
    to {
      opacity: 1;
    }
  }

  animation-name: animaButton;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  width: 300px;
  height: 50px;
  margin: 60px 0;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  background-color: #010f5c;
  font-variant: small-caps;
  font-size: 16px;
  letter-spacing: 1px;

`;
