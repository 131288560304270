import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { Container, ContainerBox, AlertMsg, Next } from './styles';
import Header from '../../components/Header';
import BackBTN from '../../components/BackBTN';
import Headset from '../../assets/images/phone.svg';
import WifiTranslate from '../../Language/Wifi';
import AppUsuarios from '../../components/AppUsuarios';

export default function Channel() {
  const history = useHistory();
  const language = useSelector((state) => state.language);

  async function handleBackPage() {
    history.push('./channel')
  }

  async function verificaUsuarios() {
    const getSelectedChannel = await sessionStorage.getItem('selectedChannel');
    console.log(JSON.parse(getSelectedChannel).canal_id);
    const final = await AppUsuarios(JSON.parse(getSelectedChannel).canal_id);
    console.log(final);
    if (final == 'success')
      history.push('./audio-wifi')
    else
      mensagem();
  }


  async function mensagem() {
    confirmAlert({
      title: language == 'pt' ? 'Erro' : 'Error',
      message: language == 'pt' ? 'Acesso não permitido. A quantidade total de usuários foi atingida.'
                 : 'Unauthorized access. The total number of users has been reached.',
      buttons: [
        {
          label: 'OK',
          onClick: () => history.push('./channel')
        }
      ]
    });
  }
  

  return (
    <Container>
      <Header />
      <BackBTN handleBackPage={handleBackPage} language={language} />
      <ContainerBox>
        <img src={Headset} alt="phone-icon" />
        <AlertMsg>{WifiTranslate[language].wifi.alert}</AlertMsg>
        <Next onClick={() => verificaUsuarios()}>{WifiTranslate[language].wifi.button}</Next>
      </ContainerBox>
    </Container>
  )
}
