import { combineReducers } from 'redux';

import auth from '../Reducers/auth';
import app_busca_evento from '../Reducers/app_busca_evento';
import app_busca_sessao from '../Reducers/app_busca_sessao';
import language from '../Reducers/language';
import selectedRoom from '../Reducers/selectedRoom';
import selectedChannel from '../Reducers/selectedChannel';
import pass from '../Reducers/pass';

const Reducers = combineReducers({
  auth,
  app_busca_evento,
  app_busca_sessao,
  language,
  selectedRoom,
  selectedChannel,
  pass,
});

export default Reducers;
