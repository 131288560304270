import baseUrl from '../../Endpoints';

export default async function AppBuscaEvento(value) {
    const url = `${baseUrl}/dev/app-busca-evento`;

    let uniqueID = await localStorage.getItem('uniqueID');
    if (!uniqueID) {
      var randomString = require('random-string');
      uniqueID = randomString({length: 40});
      await localStorage.setItem('uniqueID', uniqueID);
    }

    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });

    const task = {
      "terminal_id": uniqueID,
      "evento_senha": value,
    }

    const data = JSON.stringify(task);

    const request = new Request(url, {
      method: 'POST',
      headers: headers,
      body: data,
    });

    const getFetch = fetch(request);
    const [getPromise] = await Promise.all([getFetch]);
    const getJson = await getPromise.json();

  return getJson;
    
}