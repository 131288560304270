import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  padding: 150px;
  flex-direction: column;
  @keyframes anima {
    from {
      transform: translateX(-26px);
      opacity: 0;
    }; 
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 1350px) {
    padding: 100px;
  }

  @media (max-width: 1000px) {
    padding: 100px 80px;
  }

  @media (max-width: 800px) {
    padding: 100px 40px;
  }

  @media (max-width: 600px) {
    padding: 100px 10px;
  }
`;

export const ContainerBox = styled.div`
    width: 96%;
    min-height: 50vh;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;


    @media (max-width: 600px) {
      div h3 {
        font-size: 14px;
      }
    }
`;


export const Title = styled.div`
  font-size: 26px;
  font-weight: bolder;
  align-self: flex-start;
  margin: 0 0 50px 200px;
  letter-spacing: 2px;

  @media (max-width: 1350px) {
    margin: 0 0 50px 150px;
  }

  @media (max-width: 1000px) {
    margin: 0 0 50px 100px;
  }

  @media (max-width: 800px) {
    margin: 0 0 50px 50px;
  }

  @media (max-width: 600px) {
    margin: 0 0 50px 10px;
    font-size: 20px;
  }
`;

export const Password = styled.input`
  outline: none;
  width: 100%;
  max-width: 600px;
  padding: 0px 10px;
  border: 2px solid #d8d8d8;
  font-size: 16px;
  border-radius: 6px;
  height: 100%;
`;

export const Enter = styled.button`
    border: none;
    width: 100%;
    max-width: 300px;
    height: 94%;
    background-color: ${(props) => props.isLoading ? '#fff' : '#055ff0'};
    color: #fff;
    font-weight: bolder;
    letter-spacing: 1px;
    font-size: 16px;
    font-variant: small-caps;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    margin: 0 0 0 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 700px) {
      margin: 60px 0;
      width: 100%;
      max-width: 100%;
    }
`;

export const PasswordBox = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;


  @media (max-width: 700px) {
    width: 96%;
    flex-direction: column;
    height: 250px;
  }


`;

export const Alert = styled.div`  
  color: #f00;
  position: absolute;
  left: 150px;
  bottom: -50%;

  @media (max-width: 1500px) {
    left: 110px;
    bottom: -50%;
  }


  @media (max-width: 1300px) {
    left: 80px;
    bottom: -50%;
  }

  @media (max-width: 1150px) {
    left: 10px;
    bottom: -50%;
  }


  @media (max-width: 1000px) {
    left: 10px;
    bottom: -50%;
  }

  @media (max-width: 700px) {
    left: 10px;
    top: 70px;
    
  }

  @media (max-width: 620px) {
    left: 10px;
    top: 70px;
  }

`;
