import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isSafari, isIOS, isChrome, isWindows } from "react-device-detect";
import {
  Container,
  ContainerBG,
  LoginBox,
  LoginForm,
  LoginPassword,
  PontoLogo,
  Lock,
  Loading,
  Alert,
} from "./styles";
import data from "../../components/storage";
import Language from "../../components/Language";
import ponto from "../../assets/images/ponto.png";
import powered from "../../assets/images/poweredlsitec.png";
import lock from "../../assets/images/lock.svg";
import load from "../../assets/images/white-load.gif";
import PopupSafariIOS from "./components/popupSafariIOS";
import AppBuscaEvento from "../../components/AppBuscaEvento";
import { useEffect } from "react";

import languageJson from "../../Language/Login";
import { confirmAlert } from "react-confirm-alert";

export default function Login({ location, props_app_busca_evento }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [popupSafariIOS, setPopupSafariIOS] = useState(false);
  const language = useSelector((state) => state.language);

  //const AppBuscaEventoJSON = useSelector((state) => state.app_busca_evento);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(async () => {
    console.log("start");

    if (sessionStorage.getItem("data") == "[object Object]")
      sessionStorage.removeItem("data");

    if (
      sessionStorage.getItem("selectedChannel") != undefined &&
      sessionStorage.getItem("selectedRoom") != undefined &&
      sessionStorage.getItem("data") != undefined
    ) {
      console.log("wifi");
      return history.push("/wifi");
    } else if (
      sessionStorage.getItem("selectedRoom") != undefined &&
      sessionStorage.getItem("data") != undefined
    ) {
      console.log("wifi2");
      return history.push("/channel");
    } else if (sessionStorage.getItem("data") != undefined) {
      if (JSON.parse(sessionStorage.getItem("data")).auth) {
        console.log("wifi3");
        return history.push("/rooms");
      }
    }
    dispatch({ type: "change_auth", current: false });

    const pinGravado = await localStorage.getItem("pin");
    const saiu = await sessionStorage.getItem("exit");
    const evento = await localStorage.getItem("evento");
    if (
      pinGravado != undefined &&
      pinGravado.length >= 6 &&
      evento != undefined
    ) {
      confirmAlert({
        title: language == "pt" ? "Entrar" : "Sign in",
        message:
          language == "pt"
            ? "O último evento que você acessou foi: " +
              evento +
              ". Deseja continuar neste evento?"
            : "The last event that you accessed was: " +
              evento +
              ". Do you want to continue in this event?",
        buttons: [
          {
            label: language == "pt" ? "Sim" : "Yes",
            onClick: () => {
              verificaPIN(pinGravado);
            },
          },
          {
            label: language == "pt" ? "Não" : "No",
            onClick: () => {
              localStorage.removeItem("pin");
              localStorage.removeItem("evento");
            },
          },
        ],
      });
    } else {
      if (saiu == "s") {
        await sessionStorage.removeItem("exit");
      } else {
        localStorage.removeItem("pin");
        localStorage.removeItem("evento");
      }
    }
    plataformDetection();
  }, []);

  // -----------------------

  async function handleLogin(e) {
    if (e.key === "Enter" || e === "btn") {
      setLoginError(false);
      setPinError(false);
      setLoading(true);
      if (value.length >= 6 && value.length <= 20) {
        const AppBuscaEventoJSON = await AppBuscaEvento(value);
        await sessionStorage.setItem("pin", value);
        handleResponse(AppBuscaEventoJSON, value);
      } else {
        setLoginError(true);
        setLoading(false);
      }
    }
  }

  async function verificaPIN(pin) {
    const response = await AppBuscaEvento(pin);
    if (response.estado === "sucesso") {
      dispatch({
        type: "add_pass",
        loginPass: pin,
      });
      dispatch({
        type: "add_app_busca_evento",
        json: response,
      });
      dispatch({
        type: "change_auth",
        current: true,
      });
      await sessionStorage.setItem("pin", pin);
      await localStorage.setItem("pin", pin);
      await localStorage.setItem("evento", response.evento.evento_titulo);
      return history.push("/home");
    } else {
      confirmAlert({
        title: language == "pt" ? "Erro" : "Error",
        message:
          response.codigo == 4
            ? languageJson[language].login.pinerror
            : languageJson[language].login.notfound,
        buttons: [
          {
            label: language == "pt" ? "Ok" : "Ok",
            onClick: () => {
              localStorage.removeItem("pin");
            },
          },
        ],
      });
    }
  }

  async function handleResponse(response, value) {
    if (response.estado === "sucesso") {
      dispatch({
        type: "add_pass",
        loginPass: value,
      });
      dispatch({
        type: "add_app_busca_evento",
        json: response,
      });
      dispatch({
        type: "change_auth",
        current: true,
      });
      await localStorage.setItem("pin", value);
      await localStorage.setItem("evento", response.evento.evento_titulo);
      return history.push("/home");
    } else if (response.codigo == 4) {
      setPinError(true);
    } else {
      setLoginError(true);
    }

    setLoading(false);
  }

  // -----------------------

  async function handleText(event) {
    let text = event.target.value;
    if (text.length <= 20) {
      setValue(text.toUpperCase());
    }
  }

  const plataformDetection = () => {
    if (isSafari && isIOS) {
      setPopupSafariIOS(false);
    }
  };

  const fecharPopupSafari = () => {
    setPopupSafariIOS(false);
  };

  // -----------------------

  return (
    <>
      <PopupSafariIOS aberto={popupSafariIOS} fechar={fecharPopupSafari} />
      <Container>
        <ContainerBG>
          <Language
            language_pt={languageJson[language].login.pt}
            language_en={languageJson[language].login.en}
          />
          <LoginBox>
            <PontoLogo>
              <img src={ponto} alt="ponto" />
            </PontoLogo>
            <LoginForm isLoading={loading}>
              <LoginPassword>
                <Lock>
                  <img src={lock} alt="lock-icon" />
                </Lock>
                <input
                  type="text"
                  placeholder={languageJson[language].login.placeholder}
                  spellCheck="false"
                  onKeyUp={handleLogin}
                  onChange={(event) => handleText(event)}
                  className="loginText"
                  value={value}
                />
              </LoginPassword>
              <Alert>
                {loginError ? languageJson[language].login.notfound : null}
              </Alert>
              <Alert>
                {pinError ? languageJson[language].login.pinerror : null}
              </Alert>
              <button onClick={() => handleLogin("btn")}>
                {loading ? (
                  <Loading src={load} alt="loading..." />
                ) : (
                  languageJson[language].login.loginButton
                )}
              </button>
            </LoginForm>
            <PontoLogo>
              <img src={powered} alt="ponto" />
            </PontoLogo>
            <h6>V. 1.7.2-29-08-2022</h6>
          </LoginBox>
        </ContainerBG>
      </Container>
    </>
  );
}
