import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, ContainerBox, AlertMsg, Next } from './styles';
import Header from '../../components/Header';
import Ponto from '../../assets/images/ponto.png';
import ExitTranslate from '../../Language/Exit';
import AppUsuarios from '../../components/AppUsuarios';

export default function Channel() {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  
  useEffect(async () => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title,  window.location.href);
    });
  }, []);

  async function handleBackPage() {
    dispatch({ type: 'change_auth', current: false})
  }

  return (
    <Container>
      <ContainerBox>
        <img src={Ponto} alt="phone-icon" />
        <AlertMsg>{ExitTranslate[language].exit.alert}</AlertMsg>
        <Next onClick={() => handleBackPage()}>{ExitTranslate[language].exit.button}</Next>
      </ContainerBox>
    </Container>
  )
}
