import styled from 'styled-components';

import bgImage from '../../assets/images/bg.png';

//

export const Container = styled.div`
  :root {
    --main-color: #718fc8;
  }

  background-image: url(${bgImage});
  width: 100%;
  height: 100vh;  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

`;

export const ContainerBG = styled.div`
  width: 100%;
  height: 100vh;
  background: #001279a1;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;


export const Input = styled.input`
  width: 100%;
  padding: 2%;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const Submit = styled.input`
  width: 100%;
  color: white;
  background-color: #8C8C8C;
  padding: 2%;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

