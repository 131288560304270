import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  padding: 150px;
  flex-direction: column;
  @keyframes anima {
    from {
      transform: translateX(-26px);
      opacity: 0;
    }; 
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }


  @media (max-width: 1350px) {
    padding: 100px;
  }

  @media (max-width: 1000px) {
    padding: 100px 80px;
  }

  @media (max-width: 800px) {
    padding: 100px 40px;
  }

  @media (max-width: 600px) {
    padding: 100px 10px;
  }
`;

export const ContainerBox = styled.div`
    width: 96%;
    min-height: 50vh;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    div h3 {
      font-size: 16px;
    }

    @media (max-width: 600px) {
      div h3 {
        font-size: 14px;
      }
    }
`;


export const Title = styled.div`
  font-size: 26px;
  font-weight: bolder;
  align-self: flex-start;
  margin: 0 0 50px 200px;
  letter-spacing: 2px;

  @media (max-width: 1350px) {
    margin: 0 0 50px 150px;
  }

  @media (max-width: 1000px) {
    margin: 0 0 50px 100px;
  }

  @media (max-width: 800px) {
    margin: 0 0 50px 50px;
  }

  @media (max-width: 600px) {
    margin: 0 0 50px 10px;
    font-size: 20px;
  }
`;

export const Micro = styled.div`
  width: 150px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 110px;
    margin: 30px 0;
  
  }
`;

export const AudioMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10vh 0;
  width: 100%;
`;

export const Logout = styled.button`
  background-color: #919191;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;

`;

export const Channels = styled.button`
  background-color: #919191;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 7vw;
  outline: none;
  border: none;

`;

export const Mute = styled.button`
  background-color: #919191;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;

`;