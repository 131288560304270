import { Modal, ModalContent, ModalBody, ModalFooter } from "../styles";
import iconBluetooth from "../../../assets/images/bluetooth-disabled.svg"
export default function PopupSafariIOS({ aberto, fechar }) {

    return (
        <>
            <Modal aberto={aberto}>
                <ModalContent>
                    <ModalBody>
                        <img src={iconBluetooth}/>
                        <h1>Aviso</h1>
                        <p>Seu fone bluetooth não se conectou ao nosso sistema. Por favor baixe o aplicativo e esse problema será resovolvido</p>
                    </ModalBody>
                    <ModalFooter>
                        <a onClick={fechar}>Não, obrigado</a>
                        <a href="https://apps.apple.com/br/app/ponto-link/id1561854147" target='_blank' rel="noreferrer">Instalar</a>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}