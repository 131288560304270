module.exports = {
    pt: {
        wifi: {
            alert: 'Se estiver em local público, por favor, coloque o fone de ouvido.',
            button: 'Continue',
        }
    },
    en: {
        wifi: {
            alert: 'If you are in a public place, please put on the headphone.',
            button: 'Continue',
        }
    }
}
