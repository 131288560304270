import styled from 'styled-components';

export const LanguageStyle = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  max-width: 300px;
  height: 60px;
  top: 0;
  right: 0; 

  @media (max-height: 350px) {
    display: none;
  }

  @media (max-width: 450px) {
    max-width: 180px;
    margin-right: 10px;
  }

  @media (max-height: 500px) and (min-width: 1060px) {
    display: flex;
  }




`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-weight: bolder;
  color: #fff;
  font-size: 14px;
  cursor: pointer;

  img {
    width: 26px;
    height: 26px;
    margin: 0 5px;

  }

  @media (max-width: 450px) {
    font-size: 12px;
    img {
      width: 20px;
      height: 20px;
      margin: 0 2px;
    }
  }


`;
