module.exports = {
  pt: {
    Channel: {
     title: 'Selecione um canal',
    }
  },
  en: {
    Channel: {
      title: 'Select a channel',
    }
  }
}