import {useDispatch} from 'react-redux';

import { LanguageStyle, Option } from './styles';
import portugues from '../../assets/images/brasil.png';
import ingles from '../../assets/images/english.png'


export default function Language({ language_pt, language_en }) {
  const dispatch = useDispatch();

  return (
    <LanguageStyle>
      <Option  onClick={() => dispatch({type: 'change_language', language: 'pt'})}>
        <img src={portugues} alt="brasil" />
        {language_pt}
      </Option>
      <Option  onClick={() => dispatch({type: 'change_language', language: 'en'})}>
        <img src={ingles} alt="brasil" />
        {language_en}</Option>
    </LanguageStyle>
  )
}