import styled from 'styled-components';

export const Back = styled.div`
position: absolute;
top: 120px;
left: 100px;
text-decoration: underline;
display: flex;
align-items: center;
font-weight: bolder;
cursor: pointer;
color: #00000080;

img {
  margin-right: 5px;
}


@media (max-width: 1400px) {
  top: 110px;
  left: 80px;
}


@media (max-width: 1100) {
  top: 90px;
  left: 60px;
}

@media (max-width: 620px) {
  top: 100px;
  left: 30px;
}
`;